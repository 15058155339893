body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.header-sider-trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}

.header-sider-trigger:hover {
  color: #1890ff;
}

.layout-content {
  margin: 24px 16px;
  background: #fff;
  overflow: visible;
  overflow: initial;
}

.logo {
  position: relative;
  transition: all 0.3s;
  overflow: hidden;
  height: 32px;
  background: rgba(255,255,255, 0);
  margin: 16px;
}

.logo img {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
}

.logo  h1 {
  color: white;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  margin: 0 0 0 12px;
  font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 400;
}
.login-form {
  min-width: 300px;
  max-width: 400px;
  width: 30vw;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
