.logo {
  position: relative;
  transition: all 0.3s;
  overflow: hidden;
  height: 32px;
  background: rgba(255,255,255, 0);
  margin: 16px;
}

.logo img {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
}

.logo  h1 {
  color: white;
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  margin: 0 0 0 12px;
  font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 400;
}