.header-sider-trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}

.header-sider-trigger:hover {
  color: #1890ff;
}

.layout-content {
  margin: 24px 16px;
  background: #fff;
  overflow: initial;
}
