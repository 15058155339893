.login-form {
  min-width: 300px;
  max-width: 400px;
  width: 30vw;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}